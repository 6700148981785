import * as yup from 'yup'

import { getValidationMessage } from 'App/utils/validateFieldsSchema'

const schema = {
  email: yup.string()
    .required(getValidationMessage('email', 'Campo e-mail é obrigatório'))
    .email(getValidationMessage('email', 'Informe um e-mail válido')),
  password: yup.string().required(getValidationMessage('password', 'Campo senha é obrigatório')),
}

export default yup.object(schema)
