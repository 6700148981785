import { useDispatch } from 'react-redux'

import * as Types from './actionsTypes'

const useAuthStore = () => {
  const dispatch = useDispatch()

  const setUser = (user) => {
    dispatch({
      type: Types.SET_LOGGED_USER,
      payload: user,
    })
  }

  return {
    setUser,
  }
}

export default useAuthStore