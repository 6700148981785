import { lazy } from 'react'

export default [
  {
    name: 'listThemes',
    path: ['/temas'],
    component: lazy(() => import('./views/List')),
    exact: true,
    title: 'Temas',
    allowedProfiles: [],
  },
  {
    name: 'createTheme',
    path: ['/temas/criar'],
    component: lazy(() => import('./views/Create')),
    exact: true,
    title: 'Criar tema',
    allowedProfiles: [],
  },
  {
    name: 'editTheme',
    path: ['/temas/:id'],
    component: lazy(() => import('./views/Edit')),
    exact: true,
    title: 'Editar tema',
    allowedProfiles: [],
  },
]
