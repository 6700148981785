import { lazy } from 'react'

export default [
  {
    name: 'listCompanies',
    path: ['/empresas'],
    component: lazy(() => import('./views/List')),
    exact: true,
    title: 'Artigos',
    allowedProfiles: [],
  },
  {
    name: 'createCompany',
    path: ['/empresas/solicitacoes'],
    component: lazy(() => import('./views/Solicitations')),
    exact: true,
    title: 'Solicitações de empresas',
    allowedProfiles: [],
  },
  {
    name: 'createCompany',
    path: ['/empresas/criar'],
    component: lazy(() => import('./views/Create')),
    exact: true,
    title: 'Criar empresa',
    allowedProfiles: [],
  },
  {
    name: 'editCompany',
    path: ['/empresas/:id'],
    component: lazy(() => import('./views/Edit')),
    exact: true,
    title: 'Editar empresa',
    allowedProfiles: [],
  },
]
