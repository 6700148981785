import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { toast } from 'react-toastify'

import httpCodes from 'App/enums/httpCodes'
import validateFieldsSchema, { ValidationError } from 'App/utils/validateFieldsSchema'
import { allowedProfiles } from 'App/utils/profilesTypes'

import useAuthStore from 'Auth/store/useAuthStore'
import service from 'Auth/service'
import schema from 'Auth/utils/loginSchema'
import { login } from 'Auth/utils'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles({
  form: {
    marginTop: '15px',
  },
  input: {
    marginBottom: '20px',
  },
})

const Login = () => {
  const classes = useStyles()
  const { setUser } = useAuthStore()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [validationErrors, setValidationErrors] = useState({})
  const [isLoading, setLoading] = useState(false)

  const handleChangeEmail = (event) => {
    setEmail(event.target.value)
    setValidationErrors({
      ...validationErrors,
      email: null,
    })
  }

  const handleChangePassword = (event) => {
    setPassword(event.target.value)
    setValidationErrors({
      ...validationErrors,
      password: null,
    })
  }

  const handleLogin = async (event) => {
    event.preventDefault()
    setLoading(true)
    setValidationErrors({})

    try {
      const payload = {
        email,
        password,
      }

      const validation = await validateFieldsSchema(schema, payload)

      if (validation) {
        setValidationErrors({
          ...validationErrors,
          ...validation,
        })
        throw new ValidationError()
      }

      const { data } = await service.login(payload)

      if (!allowedProfiles.includes(data.user?.role)) {
        throw { message: 'Acesso não autorizado' }
      }

      login({ user: data.user, token: data.token })
    } catch (error) {
      if (error?.type === 'validation') {
        toast.error('Preencha todos os campos corretamente')
      } else if (error.response?.status === httpCodes.unauthorized) {
        toast.error('E-mail ou Senha inválida')
      } else {
        toast.error(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <form
        autoComplete="off"
        className={classes.form}
        noValidate
        onSubmit={handleLogin}
      >
        <Grid container>
          <Grid item xs={12}>
            <TextField
              className={classes.input}
              disabled={isLoading}
              error={!!validationErrors.email}
              helperText={validationErrors.email || null}
              InputLabelProps={{
                shrink: true,
              }}
              label="E-mail"
              type="email"
              value={email}
              variant="outlined"
              fullWidth
              onChange={handleChangeEmail}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.input}
              disabled={isLoading}
              error={!!validationErrors.password}
              helperText={validationErrors.password || null}
              InputLabelProps={{
                shrink: true,
              }}
              label="Senha"
              type="password"
              value={password}
              variant="outlined"
              fullWidth
              onChange={handleChangePassword}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              disabled={isLoading}
              type="submit"
              variant="contained"
              fullWidth
            >
              {isLoading ? <CircularProgress /> : 'Entrar'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}

export default Login
