import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { logout } from 'Auth/utils'

import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'

import AccountCircle from '@material-ui/icons/AccountCircle'

const userMenuId = `user-menu-${uuid()}`

const UserMenu = () => {
  const history = useHistory()

  const [anchorEl, setAnchorEl] = useState(null)

  const isOpen = Boolean(anchorEl)

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    logout()
    history.push('/')
  }

  return (
    <div>
      <IconButton
        aria-controls={userMenuId}
        aria-haspopup="true"
        onClick={handleOpen}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>

      <Menu
        id={userMenuId}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpen}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout}>
          Sair
        </MenuItem>
      </Menu>
    </div>
  )
}

export default UserMenu
