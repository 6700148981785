import List from '@material-ui/core/List'

import MenuItem from './MenuItem'

import items from './items'

const Menu = () => {
  return (
    <div>
      <List
        component="div"
        disablePadding
      >
        {items.map((item) => (
          <MenuItem
            key={item.key}
            item={item}
          />
        ))}
      </List>
    </div>
  )
}

export default Menu
