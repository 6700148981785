import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import { isNotEmpty } from 'App/utils/array'

import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

const MenuItem = ({ item, level }) => {
  const history = useHistory()

  const [isOpen, setOpen] = useState(false)

  const hasItems = isNotEmpty(item.items)
  const Icon = item.icon || null

  const handleToggle = () => {
    setOpen(!isOpen)
  }

  const handlePath = () => {
    history.push(item.path)
  }

  const handleClick = () => {
    if (hasItems) {
      handleToggle()
    } else {
      handlePath()
    }
  }

  return (
    <>
      <ListItem
        button
        style={{ paddingLeft: `${16 * level}px` }}
        onClick={handleClick}
      >
        {Icon && (
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
        )}

        <ListItemText primary={item.text} />

        {hasItems && (
          isOpen ? <ExpandLess /> : <ExpandMore />
        )}
      </ListItem>

      {hasItems && (
        <Collapse
          in={isOpen}
          timeout="auto"
          unmountOnExit
        >
          <List
            component="div"
            disablePadding
          >
            {item.items.map((subitem) => (
              <MenuItem
                key={subitem.key}
                item={subitem}
                level={level + 1}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  )
}

MenuItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
}

MenuItem.defaultProps = {
  item: {},
  level: 1,
}

export default MenuItem
