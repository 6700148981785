import { lazy } from 'react'

export default [
  {
    name: 'editComplaintCompany',
    path: ['/denuncias/:id/empresa'],
    component: lazy(() => import('./views/EditComplaintCompany')),
    exact: true,
    title: 'Editar Empresa da Denúncia',
    allowedProfiles: [],
  },
  {
    name: 'listComplaintRequests',
    path: ['/denuncias/solicitacoes'],
    component: lazy(() => import('./views/Requests')),
    exact: true,
    title: 'Solicitações de Denúncia',
    allowedProfiles: [],
  },
  {
    name: 'editComplaintRequests',
    path: ['/denuncias/solicitacoes/:id'],
    component: lazy(() => import('./views/EditComplaintRequest')),
    exact: true,
    title: 'Solicitações de Denúncia',
    allowedProfiles: [],
  },
]
