import { lazy } from 'react'

export default [
  {
    name: 'listArticles',
    path: ['/artigos'],
    component: lazy(() => import('./views/Articles/List')),
    exact: true,
    title: 'Artigos',
    allowedProfiles: [],
  },
  {
    name: 'createArticle',
    path: ['/artigos/criar'],
    component: lazy(() => import('./views/Articles/Create')),
    exact: true,
    title: 'Criar artigo',
    allowedProfiles: [],
  },
  {
    name: 'editArticle',
    path: ['/artigos/:id'],
    component: lazy(() => import('./views/Articles/Edit')),
    exact: true,
    title: 'Editar artigo',
    allowedProfiles: [],
  },
  {
    name: 'listNews',
    path: ['/noticias'],
    component: lazy(() => import('./views/News/List')),
    exact: true,
    title: 'Notícias',
    allowedProfiles: [],
  },
  {
    name: 'createNews',
    path: ['/noticias/criar'],
    component: lazy(() => import('./views/News/Create')),
    exact: true,
    title: 'Criar notícia',
    allowedProfiles: [],
  },
  {
    name: 'editNews',
    path: ['/noticias/:id'],
    component: lazy(() => import('./views/News/Edit')),
    exact: true,
    title: 'Editar notícia',
    allowedProfiles: [],
  },
]
