import { v4 as uuid } from 'uuid'

import { isNotEmpty } from 'App/utils/array'

import BubbleChartIcon from '@material-ui/icons/BubbleChart'
import BusinessIcon from '@material-ui/icons/Business'
import CategoryIcon from '@material-ui/icons/Category'
import FacebookIcon from '@material-ui/icons/Facebook'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import ListIcon from '@material-ui/icons/List'
import StarIcon from '@material-ui/icons/Star'
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import MailOutlineIcon from '@material-ui/icons/MailOutline'

const generateItemsKeys = (item) => ({
  key: `menu-item-key-${uuid()}`,
  ...item,
  items: isNotEmpty(item.items) ? item.items.map(generateItemsKeys) : [],
})

const items = [
  {
    text: 'Solicitações',
    icon: NewReleasesIcon,
    items: [
      {
        text: 'Contas de empresas',
        path: '/usuarios/solicitacoes',
      },
      {
        text: 'Empresas',
        path: '/empresas/solicitacoes',
      },
      {
        text: 'Denúncias',
        path: '/denuncias/solicitacoes',
      },
    ],
  },
  {
    text: 'Quem somos',
    icon: StarIcon,
    path: '/paginas/quem-somos',
  },
  {
    text: 'Páginas',
    icon: FileCopyIcon,
    items: [
      {
        text: 'Todas',
        path: '/paginas',
      },
    ],
  },
  {
    text: 'Artigos',
    icon: FormatListBulletedIcon,
    items: [
      {
        text: 'Todos',
        path: '/artigos',
      },
      {
        text: 'Criar',
        path: '/artigos/criar',
      },
    ],
  },
  {
    text: 'Notícias',
    icon: ListIcon,
    items: [
      {
        text: 'Todas',
        path: '/noticias',
      },
      {
        text: 'Criar',
        path: '/noticias/criar',
      },
    ],
  },
  {
    text: 'Temas',
    icon: CategoryIcon,
    items: [
      {
        text: 'Todos',
        path: '/temas',
      },
      {
        text: 'Criar',
        path: '/temas/criar',
      },
    ],
  },
  {
    text: 'Empresas',
    icon: BusinessIcon,
    items: [
      {
        text: 'Todas',
        path: '/empresas',
      },
      {
        text: 'Criar',
        path: '/empresas/criar',
      },
    ],
  },
  {
    text: 'Sugestões',
    icon: BubbleChartIcon,
    path: '/sugestoes',
  },
  {
    text: 'Social',
    icon: FacebookIcon,
    path: '/social',
  },
  {
    text: 'Cookies',
    icon: LibraryAddCheckIcon,
    path: '/cookies',
  },
  {
    text: 'Contatos',
    icon: MailOutlineIcon,
    path: '/contatos',
  },
].map(generateItemsKeys)

export default items
