import { lazy } from 'react'

export default [
  {
    name: 'listSolicitations',
    path: ['/usuarios/solicitacoes'],
    component: lazy(() => import('./views/Solicitations')),
    exact: true,
    title: 'Solicitações de contas de empresas',
    allowedProfiles: [],
  },
  {
    name: 'viewUser',
    path: ['/usuarios/:id'],
    component: lazy(() => import('./views/View')),
    exact: true,
    title: 'Usuário',
    allowedProfiles: [],
  },
  {
    name: 'editUserCompany',
    path: ['/usuarios/:id/empresa'],
    component: lazy(() => import('./views/EditUserCompany')),
    exact: true,
    title: 'Editar Empresa do Usuário',
    allowedProfiles: [],
  },
  {
    name: 'listContacts',
    path: ['/contatos'],
    component: lazy(() => import('./views/Contacts/List')),
    exact: true,
    title: 'Contatos',
    allowedProfiles: [],
  },
  {
    name: 'viewContact',
    path: ['/contatos/:id'],
    component: lazy(() => import('./views/Contacts/View')),
    exact: true,
    title: 'Contato',
    allowedProfiles: [],
  },
]
