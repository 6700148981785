import { lazy } from 'react'

export default [
  {
    name: 'optionsSuggestions',
    path: ['/sugestoes'],
    component: lazy(() => import('./views/Suggestions')),
    exact: true,
    title: 'Sugestões',
    allowedProfiles: [],
  },
  {
    name: 'optionsSocial',
    path: ['/social'],
    component: lazy(() => import('./views/Social')),
    exact: true,
    title: 'Social',
    allowedProfiles: [],
  },
  {
    name: 'optionsCookies',
    path: ['/cookies'],
    component: lazy(() => import('./views/Cookies')),
    exact: true,
    title: 'Cookies',
    allowedProfiles: [],
  },
]
