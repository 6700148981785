export const isNotEmpty = (array) => Array.isArray(array) && array.length > 0
export const isEmpty = (array) => Array.isArray(array) && array.length === 0
export const arrayUnique = (array) => [...new Set(array)]
export const generateSkeletonKeys = (size) => [...new Array(size)]
  .map((_, k) => `skeleton-key-${k}`)
export const reorder = (list, currentIndex, newIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(currentIndex, 1)
  result.splice(newIndex, 0, removed)

  return result
}
