import * as yup from 'yup'
import { isEmpty } from 'lodash'

export const getValidationMessage = (field, message = 'Campo obrigatório') => ({
  field,
  message,
})

export class ValidationError {
  constructor(message = 'Contém campos com erro') {
    this.type = 'validation'
    this.name = 'Erro de validação de campos no schema'
    this.message = message
    this.stack = new Error().stack
  }
}

const validateFieldsSchema = async (schema = yup.object().shape({}), fields = {}) => {
  const errors = {}

  try {
    await schema.validate(fields, { abortEarly: false })
  } catch (error) {
    if (error.name === 'ValidationError') {
      error.errors.forEach((validationError) => {
        if (validationError?.field) {
          errors[validationError.field] = validationError.message
        }
      })
    }
  }

  if (isEmpty(errors)) {
    return false
  }

  return errors
}

export default validateFieldsSchema
