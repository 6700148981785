import pagesRoutes from 'Pages/routes'
import themesRoutes from 'Themes/routes'
import postsRoutes from 'Posts/routes'
import optionsRoutes from 'Options/routes'
import companiesRoutes from 'Companies/routes'
import usersRoutes from 'Users/routes'
import complaintsRoutes from 'Complaints/routes'

export default [
  ...pagesRoutes,
  ...themesRoutes,
  ...postsRoutes,
  ...optionsRoutes,
  ...companiesRoutes,
  ...usersRoutes,
  ...complaintsRoutes,
]
