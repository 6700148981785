const profilesTypes = {
  admin: 'admin',
  editor: 'editor',
  user: 'user',
  company: 'company',
}

export const allowedProfiles = [
  profilesTypes.admin,
  profilesTypes.editor,
]

export default Object.freeze(profilesTypes)
