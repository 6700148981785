import { useState, Suspense } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'

import { allowedProfiles } from 'App/utils/profilesTypes'
import routes from 'App/routes'

import AppBar from '@material-ui/core/AppBar'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import MenuIcon from '@material-ui/icons/Menu'

import Login from 'Auth/views/Login'

import UserMenu from 'App/components/UserMenu'
import Menu from 'App/components/Menu'

import { useStyles } from './Dashboard.styles.js'

const Dashboard = () => {
  const classes = useStyles()
  const loggedUser = useSelector((state) => state.auth.loggedUser)

  const [isDrawerOpen, setDrawerOpen] = useState(false)

  const handleDrawerToggle = () => {
    setDrawerOpen(!isDrawerOpen)
  }

  if (!loggedUser?.role || !allowedProfiles.includes(loggedUser.role)) {
    return <Login />
  }

  const container = window !== undefined ? () => window.document.body : undefined

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            className={classes.title}
            variant="h6"
            noWrap
          >
            Cadê meu dado?
          </Typography>
          <UserMenu />
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <Menu />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <Menu />
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Suspense fallback={<div>Carregando...</div>}>
          <Switch>
            <Route
              exact
              path="/"
              render={() => <Redirect to="/denuncias/solicitacoes" />}
            />

            {routes.map((route) => (
              <Route
                key={route.name}
                {...route}
              />
            ))}
          </Switch>
        </Suspense>
      </main>
    </div>
  )
}

export default Dashboard
