import { lazy } from 'react'

export default [
  {
    name: 'listPages',
    path: ['/paginas'],
    component: lazy(() => import('./views/List')),
    exact: true,
    title: 'Páginas',
    allowedProfiles: [],
  },
  {
    name: 'pageWhoWeAre',
    path: ['/paginas/quem-somos'],
    component: lazy(() => import('./views/Fixeds/WhoWeAre')),
    exact: true,
    title: 'Quem somos',
    allowedProfiles: [],
  },
  {
    name: 'editPage',
    path: ['/paginas/:id'],
    component: lazy(() => import('./views/Edit')),
    exact: true,
    title: 'Editar página',
    allowedProfiles: [],
  },
]
