import { parseCookies, setCookie, destroyCookie } from 'nookies'

import store from 'App/store/store'
import { SET_LOGGED_USER, LOGOUT } from 'Auth/store/actionsTypes'

const tokenCookieName = '@cademeudado.token'

export const getAuthToken = () => (parseCookies()[tokenCookieName] || null)
export const isAuthenticated = () => !!getAuthToken()

export const getRoleFromToken = () => {
  const token = getAuthToken()
  let data = null

  if (!token || !(data = token.split('.')[1])) {
    return null
  }

  try {
    data = JSON.parse(atob(data))
    const role = data?.role || null

    return role
  } catch {
    return null
  }
}

export const setAuthTokenCookie = (token) => {
  const TWENTY_FOUR_HOURS_IN_SECONDS = 60 * 60 * 24
  const bearerToken = token.startsWith('Bearer') ? token : `Bearer ${token}`

  setCookie(null, tokenCookieName, bearerToken, {
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
    maxAge: TWENTY_FOUR_HOURS_IN_SECONDS,
    path: '/',
  })

  return bearerToken
}

export const removeAuthTokenCookie = () => {
  destroyCookie(null || {}, tokenCookieName, {
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
    path: '/',
  })
}

export const login = ({ user, token }) => {
  setAuthTokenCookie(token)
  store.dispatch({ type: SET_LOGGED_USER, payload: user })
}

export const logout = () => {
  removeAuthTokenCookie()
  store.dispatch({ type: LOGOUT })
}
