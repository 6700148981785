import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import store from 'App/store/store'
import theme from 'App/theme'

import App from 'App'

import 'react-toastify/dist/ReactToastify.css'

ReactDOM.render(
  <StrictMode>
    <CssBaseline />

    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>

    <ToastContainer
      autoClose={5000}
      position={toast.POSITION.BOTTOM_LEFT}
      closeOnClick
      pauseOnHover
    />
  </StrictMode>,
  document.getElementById('root')
);
