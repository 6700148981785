const INITIAL_STATE = {}

const users = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  default:
    return state
  }
}

export default users
