import { getRoleFromToken } from 'Auth/utils'

import {
  SET_LOGGED_USER,
  SET_LOGGED_USER_ROLE,
  LOGOUT,
} from './actionsTypes'

const INITIAL_STATE = {
  loggedUser: {
    role: getRoleFromToken(),
  },
}

const auth = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case SET_LOGGED_USER: {
    return {
      ...state,
      loggedUser: {
        ...state.loggedUser,
        ...action.payload,
      },
    }
  }
  case SET_LOGGED_USER_ROLE: {
    return {
      ...state,
      loggedUser: {
        ...state.loggedUser,
        role: action.payload,
      },
    }
  }
  case LOGOUT: {
    return {
      ...state,
      loggedUser: null,
    }
  }
  default:
    return state
  }
}

export default auth
